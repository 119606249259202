
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Features() {
  return (
    <MKBox component="section" py={3} mt={4}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <DefaultInfoCard
              color="info"
              icon="electric_bolt"
              title="Fast-To-Market"
              description="Design your intake forms, file requirements and workflows for your offerings - without writing any code."
              direction="center"
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}> */}
          {/*   <DefaultInfoCard */}
          {/*     color="primary" */}
          {/*     icon="edit_note" */}
          {/*     title="Form Design" */}
          {/*     description="Capture customer information seamlessly with our user-friendly form design tools." */}
          {/*     direction="center" */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} md={6} lg={4}> */}
          {/*   <DefaultInfoCard */}
          {/*     color="warning" */}
          {/*     icon="folder_shared" */}
          {/*     title="File Sharing" */}
          {/*     description="Allow customers to share essential documents and files securely within the platform." */}
          {/*     direction="center" */}
          {/*   /> */}
          {/* </Grid> */}
          <Grid item xs={12} md={6} lg={3}>
            <DefaultInfoCard
              color="success"
              icon="task_alt"
              title="Frictionless Experience"
              description="Purpose built data connectors to retrieve existing data from your sources to avoid re-filling."
              direction="center"
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}> */}
          {/*   <DefaultInfoCard */}
          {/*     color="success" */}
          {/*     icon="task_alt" */}
          {/*     title="Case Management" */}
          {/*     description="Efficiently review submissions, assign tasks, and collaborate seamlessly within the platform." */}
          {/*     direction="center" */}
          {/*   /> */}
          {/* </Grid> */}
          <Grid item xs={12} md={6} lg={3}>
            <DefaultInfoCard
              color="primary"
              icon="open_in_full"
              title="Scalable and Adaptable"
              description="Grow without constraints for your intake process. Adapt fast with evolving market needs."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DefaultInfoCard
              color="warning"
              icon="stream"
              title="Seamless Integrations"
              description="Integrate seamlessly with your internal or external systems for a cohesive ecosystem. "
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Features;
