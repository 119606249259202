import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

export default memo(({ data, isConnectable }) => {
  return (
    <MKBox>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <MKTypography variant="caption">
        Custom Color Picker Node: <strong>{data.color}</strong>
      </MKTypography>
      <br />
      <input className="nodrag" type="color" onChange={data.onChange} defaultValue={data.color}
      />
      <MKTypography variant="caption">
        {JSON.stringify(data)}
      </MKTypography>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={{ top: 10, background: "#555" }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={{ bottom: 10, top: "auto", background: "#555" }}
        isConnectable={isConnectable}
      />
    </MKBox>
  );
});
