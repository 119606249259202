
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";

// MKit React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/cs/sqr-imgs/1.png";
import bgBack from "assets/images/cs-bg/Cs-bg-1.png";

function AppBuilderDesc() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                // color={"info"}
                icon="touch_app"
                title={
                  <>
                    Drag-n-Drop
                    <br />
                    Application Builder
                  </>
                }
                description="Easily create custom forms including file sharing tailored to your business needs."
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="Drag-and-drop simplicity ensures that even complex workflows are a breeze to design."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "start my workflow",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Intuitive Form Design"
                  description="Capture customer information seamlessly with our user-friendly form design capabilities."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Document and File Sharing"
                  description="Allow customers to share essential documents and files securely within the platform."
                />
              </Grid>
            </Grid>
            {/* <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}> */}
            {/*   <Grid item xs={12} md={6}> */}
            {/*     <DefaultInfoCard */}
            {/*       icon="price_change" */}
            {/*       title="Save Time & Money" */}
            {/*       description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System." */}
            {/*     /> */}
            {/*   </Grid> */}
            {/*   <Grid item xs={12} md={6}> */}
            {/*     <DefaultInfoCard */}
            {/*       icon="devices" */}
            {/*       title="Fully Responsive" */}
            {/*       description="Regardless of the screen size, the website content will naturally fit the given resolution." */}
            {/*     /> */}
            {/*   </Grid> */}
            {/* </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AppBuilderDesc;
