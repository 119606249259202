import axios from "axios";
import { environment } from "../environments/environment.prod";
export const regApiGatewayUrl = environment.apiGatewayUrl;

// export async function getAuthToken() {
//   try {
//     const session = await Auth.currentSession();
//     const idToken = session.getIdToken();
//     return idToken.getJwtToken();
//   } catch (error) {
//     console.error('error REST API:', error);
//   }
// }

// export default async function authHeaders() {
//   const token = await getAuthToken();
//   return {
//     // 'Access-Control-Allow-Origin': '*',
//     Authorization: "Bearer ".concat(token),
//   };
// }

// export async function execTenantAPIpost(uri:string, payload) {
//   const headers = await authHeaders();
//   return await API.post(apiName, uri, {
//     headers,
//     body: payload,
//   });
//
//   //   .then(res=>{
//   //   // console.log(JSON.stringify(res));
//   //   return res;
//   // })
//   //   .catch(e=>{
//   //     console.log(JSON.stringify(e));
//   //     return {status: "Error", message:"Something went wrong!"}
//   //   })
// }

async function sendMessage(messageBody) {
  // const headers = await authHeaders();
  return await axios.post(regApiGatewayUrl + 'sesmail/send', messageBody);
}


const CSApiService = {
  sendMessage
}


export default CSApiService;