// react-router-dom components
import { useNavigate } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Presentation page components
import ExampleCard from "../components/ExampleCard";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import img1 from "assets/images/cs/CS-screens/4.png";
import img2 from "assets/images/cs/CS-screens/5.png";
import img3 from "assets/images/cs/CS-screens/12.png";
import img4 from "assets/images/cs/CS-screens/15.png";

// import bgPattern from "assets/images/shapes/pattern-lines.svg";

function WebFronts() {
  const navigate = useNavigate();
  return (
    <MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 12 }} mx={-2}>
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width={{ xs: "auto", lg: "100%" }}
        height={{ xs: "100%", lg: "auto" }}
        opacity={0.6}
      />
      <Container>
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            lg={4}
            sx={{ my: "auto", py: 6, pr: { xs: 3, lg: 2 }, pl: { xs: 3, lg: 0 } }}
          >

            <MKTypography variant="h5" color="white" mb={3}
            >
              You don't have to re-invent the wheels.


            </MKTypography>
            <MKTypography variant="h3" color="white" mb={3}
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["3xl"]
                            }
                          })}>
              CONSEN provides built-in digital-fronts for your business.
            </MKTypography>


            <MKBox display="flex" alignItems="center">
              <MKButton variant="gradient" color="info" sx={{ mt: 3, mb: 2, mr: 1 }}
                        onClick={()=>navigate('/contact-us')} >
                Contact Us
              </MKButton>
              <MKButton variant="text" color="white" sx={{ mt: 3, mb: 2 }}
                        onClick={()=>navigate('/contact-us')} >
                Learn more
              </MKButton>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={8} sx={{ pl: { xs: 3, lg: 6 }, pr: { xs: 3, lg: 6 }, pb: 4 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={6} sx={{ mt: { xs: 0, lg: 8 } }} textAlign="center">
                <ExampleCard image={img1} display="grid" minHeight="max-content" p={0} />
                <MKTypography variant="h6" color="white" mb={0}>
                  Customer Landing Page
                </MKTypography>
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mt: { xs: 0, lg: 6 } }} textAlign="center">
                <ExampleCard image={img2} display="grid" minHeight="max-content" pt={2} />
                <MKTypography variant="h6" color="white" mb={0}>
                  Customer Portal
                </MKTypography>
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mt: { xs: 0, lg: 6 } }} textAlign="center">
                <ExampleCard image={img3} display="grid" minHeight="max-content" pt={2} />
                <MKTypography variant="h6" color="white" mt={-2}>
                  Employee / Agent Dashboard
                </MKTypography>
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mt: { xs: 0, lg: 6 } }} textAlign="center">
                <ExampleCard image={img4} display="grid" minHeight="max-content" pt={0} />

                <MKTypography variant="h6" color="white" mb={0} >
                  Admin Console
                </MKTypography>
                {/* </Link> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WebFronts;
