import { useNavigate } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// MKit React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// MKit React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Coworking page sections
import Information from "pages/LandingPages/Coworking/sections/Information";
import Testimonials from "pages/LandingPages/Coworking/sections/Testimonials";
import AboutUs from "pages/LandingPages/Coworking/sections/AboutUs";
import Places from "pages/LandingPages/Coworking/sections/Places";

// Routes
// import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/cs/CS-screens/7.png";
import bgShadow from "assets/images/cs/cs-bg/vshadow/7.png";
import bgContact from "assets/images/wide_headers/6.png";

import withPublicLayout from "../../../layouts/PublicLayout";
import platformFlowImg from "../../../assets/images/cs/CS-screens/3.png";
import platformOrchestrationImg from "../../../assets/images/cs/CS-screens/9.png";
import formBuilderImg from "../../../assets/images/cs/CS-screens/15.png";
import WhatYouCanDo from "./sections/WhatYouCanDo";
import Integrations from "./sections/Integrations";
import SampleWorkflow from "../../common-sections/SampleWorkflow";
import PublicLayout from "../../../layouts/PublicLayout";

function PlatformOverview() {
  const navigate = useNavigate();

  return (
    <PublicLayout>

    <MKBox
      sx={{
        backdropFilter: "saturate(200%) blur(30px)",
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `url(${bgShadow})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        // display: "grid",
        // placeItems: "center",
      }}
    >

      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" },
              // backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              //   `url(${bgShadow})`,
              // backgroundSize: "contain",
              // backgroundPosition: "center",
              // display: "grid",
              // placeItems: "center",
          }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Enjoy the Streamlined Flexibility
            </MKTypography>
            {/* <MKTypography */}
            {/*   variant="body1" */}
            {/*   color="white" */}
            {/*   mt={1} */}
            {/*   pr={{ md: 12, lg: 24, xl: 32 }} */}
            {/*   opacity={0.8} */}
            {/* > */}
            {/*   The time is now for it be okay to be great. People in this world shun people for being */}
            {/*   nice. */}
            {/* </MKTypography> */}
            <Stack direction="row" spacing={1} mt={6} mb={3}>
              <MKButton variant="gradient" color="info"
                        onClick={()=>navigate('/contact-us')}>
                get started
              </MKButton>
              <MKButton variant="text" color="white"
                        onClick={()=>navigate('/contact-us')}>
                Learn more
              </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,


        }}
      >

        <Container sx={{ mt: 12 }}>
          {/* <MKBox component="section" py={3} mt={8}> */}
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
              <MKTypography variant="h2" fontWeight="bold" mb={0.5} color={"info"}
                            sx={({ breakpoints, typography: { size } }) => ({
                              [breakpoints.down("md")]: {
                                fontSize: size["2xl"]
                              }
                            })}
              >
                {/* Enterprise-grade platform, Small-business size effort */}
                Enterprise level features, Small-business size efforts
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Create the intake process you need on a simple-to-use platform
                built for everyone, while enjoying the power of orchestrated workflows
              </MKTypography>
            </Grid>
          </Grid>
          {/* </MKBox> */}
          {/* <SampleWorkflow /> */}
        </Container>

        <Container sx={{ mt: 6 }}>
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid item xs={12} lg={12} sx={{ textAlign: { xs: "center" } }}>
              <MKBox component="img" src={platformFlowImg} alt="platform" width="70%" />
              <MKTypography variant="body1" color="text">
                Financial Domain Example
              </MKTypography>
            </Grid>
          </Grid>

        </Container>

        <Container sx={{ mt: 6 }}>
          <Grid container spacing={3} justifyContent={"center"}>
            {/* <Grid item xs={12} lg={6} > */}


            {/*   <MKTypography variant="h3" fontWeight="bold" mb={2} */}
            {/*   > */}
            {/*     Why Consen? */}
            {/*   </MKTypography> */}


            {/*   <MKTypography variant="subtitle1" color="text" mb={4}> */}
            {/*     Preparing for customer information dependent offerings for market release is a struggle for most businesses. */}
            {/*   </MKTypography> */}

            {/*   /!* <MKTypography variant="body1" color="text" mb={4}> *!/ */}
            {/*   /!*   Your offering and workflow on Consen guides customers through completing their submissions. *!/ */}
            {/*   /!* </MKTypography> *!/ */}
            {/* </Grid> */}
            <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
              <MKTypography variant="d6" color="dark" mb={4}>
                Consen helps businesses bring their customer-data dependent offerings to the market with minimal efforts.
              </MKTypography>

            </Grid>
            {/* <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}> */}

            {/*   <MKBox component="img" src={platformOrchestrationImg} alt="platform" width="80%" /> */}
            {/* </Grid> */}
          </Grid>

        </Container>

        {/* <Information /> */}

        <WhatYouCanDo/>

        <Container sx={{ mt: 12 }}>
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
              <MKTypography variant="h2" fontWeight="bold" mb={0.5}
                            sx={({ breakpoints, typography: { size } }) => ({
                              [breakpoints.down("md")]: {
                                fontSize: size["2xl"]
                              }
                            })}
              >
                Modular Workflow Builder
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Transform your customer application workflow at lightning speed. Implement and modify workflows without the need for a tech team, ensuring swift adaptation to changing business needs in the fast-paced market.
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={12} sx={{ textAlign: { xs: "center" } }} mb={0}>
              <SampleWorkflow />
            </Grid>
            <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }} mt={-8} mb={6}>
              <MKButton color="error" variant={"gradient"}
                        onClick={()=>navigate('/contact-us')}>
                See What's Possible in Workflow
              </MKButton>
              <br/>
              <MKTypography variant="caption" color="text">
                Please let us know if something you need is not present.
              </MKTypography>
            </Grid>

          </Grid>


        </Container>

        <Container sx={{ mt: 12 }}>
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
              <MKTypography variant="h2" fontWeight="bold" mb={0.5}
                            sx={({ breakpoints, typography: { size } }) => ({
                              [breakpoints.down("md")]: {
                                fontSize: size["2xl"]
                              }
                            })}
              >
                Robust Form Builder with File Upload
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Build any form to collect information and files securely from customers.
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={12} sx={{ textAlign: { xs: "center" } }} mb={0}>
              <MKBox component="img" src={formBuilderImg} alt="consen form builder" width="60%" />
            </Grid>
            <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }} mt={-2} mb={6}>
              <MKButton color="info" variant={"gradient"}
                        onClick={()=>navigate('/contact-us')}>
                Contact Us
              </MKButton>
              <br/>
              <MKTypography variant="caption" color="text">
                Should you notice something you need is unavailable - Let's Talk.
              </MKTypography>
            </Grid>

          </Grid>


        </Container>


        {/* <Testimonials /> */}

        <Integrations />

        {/* <Places /> */}
        <Container>
          <MKBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="xl"
            my={24}
            p={6}
            sx={{
              backgroundImage: `url(${bgContact})`,
              backgroundSize: "cover",
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={8} lg={5}>
                <MKTypography variant="h4" color="white" fontWeight="bold">
                  Want to explore more? Let's Talk
                </MKTypography>
              </Grid>
              <Grid item xs={12} lg={6} sx={{ ml: "auto" }}>
                <MKBox width="12rem" ml="auto">
                  <MKButton variant="gradient" color="warning" fullWidth sx={{ boxShadow: "none" }}
                            onClick={()=>navigate('/contact-us')}>
                    Contact Us
                  </MKButton>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </Card>

    </MKBox>
    </PublicLayout>
  );
}

export default PlatformOverview;
