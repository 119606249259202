import PlatformOverview from "./pages/platform/overview";
import HomePage from "./pages/HomePage";
import Icon from "./assets/theme/components/icon";
import AboutUs from "./pages/Company/AboutUs";
import HelpCenter from "./pages/Support/HelpCenter";
import ContactUs from "./pages/ContactUs";
import CookiePolicy from "./pages/legal/CookiePolicy";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import TermsOfUse from "./pages/legal/TermsOfUse";
import LandingPage from "./pages/LandingPage";

const siteRoutes = [
  {
    name: "Landing",
    key: "landing",
    route: "/home",
    component: <LandingPage/>
  },
  {
    name: "Home",
    key: "home",
    route: "/home",
    component: <HomePage/>
  },
  {
    name: "Platform Overview",
    key: "platform-overview",
    route: "/platform/overview",
    component: <PlatformOverview/>
  },
  // {
  //   name: "About Us",
  //   key: "about-us",
  //   route: "/about-us",
  //   component: <AboutUs/>
  // }
  {
    name: "Contact Us",
    key: "contact-us",
    route: "/contact-us",
    component: <ContactUs/>
  },
  {
    name: "Cookie Policy",
    key: "CP",
    route: "/CookiePolicy",
    component: <CookiePolicy/>,
  },
  {
    name: "Privacy Policy",
    key: "PP",
    route: "/PrivacyPolicy",
    component: <PrivacyPolicy/>,
  },
  {
    name: "Terms of use",
    key: "TU",
    route: "/TermsOfUse",
    component: <TermsOfUse/>,
  },
];

export default siteRoutes;
