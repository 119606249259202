
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Features() {
  return (
    <MKBox component="section" py={3} mt={4}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <DefaultInfoCard
              color="info"
              icon="electric_bolt"
              title="Data Collection"
              description="Define your forms, file requirements and customer journey - no dev required."
              direction="center"
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}> */}
          {/*   <DefaultInfoCard */}
          {/*     color="primary" */}
          {/*     icon="edit_note" */}
          {/*     title="Form Design" */}
          {/*     description="Capture customer information seamlessly with our user-friendly form design tools." */}
          {/*     direction="center" */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} md={6} lg={4}> */}
          {/*   <DefaultInfoCard */}
          {/*     color="warning" */}
          {/*     icon="folder_shared" */}
          {/*     title="File Sharing" */}
          {/*     description="Allow customers to share essential documents and files securely within the platform." */}
          {/*     direction="center" */}
          {/*   /> */}
          {/* </Grid> */}
          <Grid item xs={12} md={6} lg={3}>
            <DefaultInfoCard
              color="success"
              icon="task_alt"
              title="Case Management"
              description="Get relevant tasks created automatically and assigned to your team, and track progress. "
              direction="center"
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}> */}
          {/*   <DefaultInfoCard */}
          {/*     color="success" */}
          {/*     icon="task_alt" */}
          {/*     title="Case Management" */}
          {/*     description="Efficiently review submissions, assign tasks, and collaborate seamlessly within the platform." */}
          {/*     direction="center" */}
          {/*   /> */}
          {/* </Grid> */}
          <Grid item xs={12} md={6} lg={3}>
            <DefaultInfoCard
              color="primary"
              icon="open_in_full"
              title="Document Generation"
              description="Generate customer and regulatory documents and freeup time from redundant workload."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DefaultInfoCard
              color="warning"
              icon="stream"
              title="Process Automation"
              description="Integrate seamlessly with your internal or external systems for a streamlined operation. "
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Features;
