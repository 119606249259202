import React from "react";
// react plugin used to create google maps
// reactstrap components
import Container from "@mui/material/Container";
//
import { Helmet } from "react-helmet";
import { AppName } from "../../static-data/app-constants";
import PublicLayout from "../../layouts/PublicLayout";

// core components


function CookiePolicy() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    loadPolicyContent(window, document);
  });

  const loadPolicyContent = (w, d) => {
    var loader = function() {
      var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
      s.src = "https://cdn.iubenda.com/iubenda.js";
      tag.parentNode.insertBefore(s, tag);
    };
    if (w.addEventListener) {
      w.addEventListener("load", loader, false);
    } else if (w.attachEvent) {
      w.attachEvent("onload", loader);
    } else {
      w.onload = loader;
    }
  };

  // function (w, d) {
  //   var loader = function () {
  //     var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
  //     s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);
  //   };
  //   if(w.addEventListener){
  //     w.addEventListener("load", loader, false);}
  //   else if(w.attachEvent){
  //     w.attachEvent("onload", loader);
  //   }else{w.onload = loader;}
  // })(window, document);


  return (
    <PublicLayout title={"Cookie Policy"}>


      {/* <div className="wrapper"> */}
      {/*   <Helmet> */}
      {/*     <title>{AppName} - Cookie Policy</title> */}
      {/*   </Helmet> */}
      {/* <ContactUsHeader sizeClass={"page-header-tiny"} /> */}
      {/* <div className="main"> */}
      {/*   <div className="contact-content"> */}
          <Container>

            <br />
            <br />
            <br />
            <br />

            <a href="https://www.iubenda.com/privacy-policy/47738173/cookie-policy"
               className="iubenda-white no-brand iubenda-embed iub-no-markup iub-body-embed" title="Cookie Policy ">Cookie
              Policy</a>


            {/*<a href="https://www.iubenda.com/privacy-policy/47738173/cookie-policy"*/}
            {/*   className="iubenda-white  iubenda-embed iub-body-embed" title="Cookie Policy">Cookie Policy</a>*/}

          </Container>
      {/*   </div> */}
      {/* </div> */}

      {/* </div> */}
    </PublicLayout>
  );
}

export default CookiePolicy;
