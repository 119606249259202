
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import step1Img from "assets/images/cs/cs-steps/1.png";
import step2mg from "assets/images/cs/cs-steps/2.png";
import step3Img from "assets/images/cs/cs-steps/3.png";
import step4Img from "assets/images/cs/cs-steps/4.png";
import step5Img from "assets/images/cs/cs-steps/5.png";
import step6Img from "assets/images/cs/cs-steps/6.png";

import urWeb1Img from "assets/images/cs/CS-screens/16.png";
import cs2img from "assets/images/cs-logo/3.svg";
import urSys3Img from "assets/images/cs/cs-steps/3.png";

function WhyChoose() {
  return (
    <MKBox component="section" py={{ xs: 6, lg: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h2" mb={1}>
              Why Choose Us?
            </MKTypography>
            {/* <MKTypography variant="body2" mb={2}> */}
            {/*   CONSEN offers businesses a suite of tools, including Form Builder, Integrations for data/ID verification, and case and task management, to streamline customer information intake and control the customer experience. */}
            {/* </MKTypography> */}
          </Grid>
          <Grid item xs={12} lg={6} textAlign="right">
            <MKButton variant="gradient" color="warning">
              Contact Us
            </MKButton>
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={6}>

          {/* <Grid item xs={12} sm={6} lg={3} mb={1}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={urWeb1Img} alt="shopify" width="80%" />} */}

          {/*     title="Your Website / API" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={6} lg={6} mb={1}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={cs2img} alt="shopify" width="50%" />} */}

          {/*     title="CONSEN" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={6} lg={3} mb={1}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={urSys3Img} alt="shopify" width="80%" />} */}

          {/*     title="Optional: Your / External System" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}

          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={"search"}

              title="One stop shop"
              description="A complete customer intake management solution covering the entire intake process lifecycle."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step2mg} alt="Define Workflow / Process" width="50%" />}

              title="2. Define Workflow / Process"
              description="Pre-screening, T&C, Eligibility check*, Consent, Validations and Verifications* and many others, define your workflow that works for your business."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step3Img} alt="Configure Offerings" width="50%" />}
              title="3. Configure Offerings"
              description="List and configure your offerings so that Consen knows what to request from customers. Publish, and you are ready for submissions!"
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step4Img} alt="Application Start" width="50%" />}
              title="4. Application Start"
              description="Customers can apply from your website, on Consen.co landing page, or customer portal. "
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step5Img} alt="Submission Review" width="50%" />}
              title="5. Submission Review"
              description="Once the customer has submitted the application, your employees / agents can review and collaborate."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon={<MKBox component="img" src={step6Img} alt="Optional: Data Exchange" width="50%" />}
              title="6. Optional: Data Exchange"
              description="Optionally, finalized submission data can be sent to your configured destination for further processing or fullfillment."
              small
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} lg={4} mb={3}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={invision} alt="invision" width="12%" />} */}
          {/*     title="Digital Product Design" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={6} lg={4} mb={3}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={slack} alt="slack" width="12%" />} */}
          {/*     title="Better Communication" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={6} lg={4} mb={3}> */}
          {/*   <DefaultInfoCard */}
          {/*     icon={<MKBox component="img" src={webdev} alt="webdev" width="12%" />} */}
          {/*     title="Logo design" */}
          {/*     description="Check out our proven methods, guides, and exercises that help make work better, and people happier." */}
          {/*     small */}
          {/*   /> */}
          {/* </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhyChoose;
