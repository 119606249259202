// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

function FormField({ label, name, ...rest }) {
  return (
    <MKBox mb={1.5}>
      <Field {...rest} name={name} as={MKInput} variant="standard" label={label}
             InputLabelProps={{ shrink: true }}
             fullWidth />
      <MKBox mt={0.75}>
        <MKTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
