import React, { useEffect, useState } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// MKit React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";

import bgImage from "assets/images/cs/CS-screens/1.png";

import { Link, useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Formik, useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import FormField from "../../../components/FormField";
import CSApiService from "../../../api-service/cs-api-service";
import { CS_LANDING_GOOGLE_SITE_KEY } from "../../../static-data/app-constants";
import MKAlert from "components/MKAlert";

interface FormValues {
  name: string;
  phoneNumber: string;
  email: string;
  message: string;
}

function Contact() {

  const [alertMessage, setAlertMessage] = useState();
  const [checked, setChecked] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

  const toggleSwitch = () => setChecked(!checked);
  let _reCaptchaRef = React.createRef();


  const [initValue, setInitValue] = useState({
    name:  "",
    phoneNumber:  "",
    email:  "",
    message:  "",

  });
  // const [initValue, setInitValue] = useState({
  //   name: "John",
  //   phoneNumber: "",
  //   email: "devops@aceoar.com",
  //   message: "This is a Test Message"
  //
  // });

  const form = {
    formId: "contact-us-form",
    formField: {
      name: {
        name: "name",
        label: "Full Name*",
        type: "text",
        errorMsg: "Full Name is required.",
        invalidMsg: "Full Name is invalid.",
        placeholder: "e.g. John"
      },
      phoneNumber: {
        name: "phoneNumber",
        label: "Phone Number",
        type: "text",
        errorMsg: "Phone Number is required.",
        invalidMsg: "Phone Number is invalid.",
        placeholder: "e.g. 123-456-7890"
      },
      email: {
        name: "email",
        label: "Email*",
        type: "text",
        invalidMsg: "Email is invalid.",
        errorMsg: "Email is required."
      },
      message: {
        name: "message",
        label: "Message*",
        type: "text",
        errorMsg: "message is required.",
        invalidMsg: "Message is not long enough",
        placeholder: "Your Message"
      }

    }
  };
  const {
    formId,
    formField: {
      name, email, message,
      phoneNumber
    }
  } = form;
  // const formik = useFormik({ initialValues: initValue });
  const validations = Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg).min(4, name.invalidMsg),
    [message.name]: Yup.string().required(message.errorMsg).min(10, message.invalidMsg),
    // [scope.name]: Yup.string().required(scope.errorMsg).oneOf(
    //   ["Public", "Private", "Protected"],
    //   t("OfferingEdit.ScopeRequired")
    // ),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [phoneNumber.name]: Yup.string().min(10, phoneNumber.invalidMsg)
  });

  const asyncScriptOnLoad = () => {
    setRecaptchaLoaded(true);
    // this.setState({ callback: "called!", recaptchaLoaded: true });
  };

  const change = (e, stateName, type) => {

  };

  const getHTMLMessageBody = (values) => Object.keys(values).map((columnName) => (
    `<div>
        <span>${columnName}: &nbsp; ${values[columnName]}</span>
     </div>`
  ));

  const onSubmit = async (values: FormValues,{ resetForm }) => {
    try {
      // setSavingData(true);
      // console.log("Contact- onSubmit : " + JSON.stringify(values));
      // e.preventDefault();
      // const token = _reCaptchaRef.current.getValue();
      // _reCaptchaRef.current.reset();

      const message = {
        Subject: "Consen.Landing Contact Form",
        HTMLBody: "<html>" + getHTMLMessageBody(values) + "</html>",
        TXTBody: JSON.stringify(values)
      };
      // console.log("updated values : " + JSON.stringify( updValues));
      await CSApiService.sendMessage(message).then(()=>resetForm());

      // // setSavingData(false);
      // onEditOfferingUpdate();
    } catch (err) {
      console.log(err);
      setAlertMessage("Failed to send message");
      // setAlertMessage(t("AddContentFailure") + ": " + err?.message);
      // setSavingData(false);
    }
  };


  return (
    <MKBox
      component="section"
      display="grid"
      position="relative"
      minHeight="90vh"
      borderRadius="xl"
      mr={{ xs: 0, lg: -2 }}
      mb={{ xs: 0, lg: -2 }}
      sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
    >
      <MKBox
        component="img"
        alt="lake house"
        src={bgImage}
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: "100%", lg: "100%" }}
        position="absolute"
        right={0}
        bottom={{ xs: "-25%", lg: "unset" }}
        top={{ xs: 0, lg: "unset" }}
        sx={{
          objectFit: "cover",
          borderTopLeftRadius: ({ borders: { borderRadius } }) => ({ xs: 0, lg: borderRadius.lg })
        }}
      />
      <Container>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
          <Grid item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              shadow="lg"
              borderRadius="xl"
              sx={{
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                  rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                px: { xs: 3, sm: 6 },
                py: { xs: 3, sm: 8 },
                mb: { xs: 0, lg: 8 },
                mt: { xs: 0, lg: -6 }
              }}
            >
              <MKTypography variant="h3" textAlign="center" mb={2}>
                Contact us
              </MKTypography>

              <Formik enableReinitialize={true}
                      initialValues={initValue}
                      validationSchema={validations}
                      onSubmit={onSubmit}

              >
                {({ values, resetForm, errors,
                    touched, isSubmitting, setFieldValue,isValid }) => (
                  <Form id={formId} autoComplete="off"
                  >
                    <MKBox py={3} px={{ xs: 3, md: 12, lg: 6, xl: 12 }}>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} md={6} lg={12} xl={6}>
                          <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={values.name}
                            placeholder={name.placeholder}
                            error={errors.name && touched.name}
                            success={values.name.length > 5 && !errors.name}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={12} xl={6}>
                          <FormField
                            type={email.type}
                            label={email.label}
                            name={email.name}
                            value={values.email}
                            // placeholder={email.placeholder}
                            error={errors.email && touched.email}
                            success={values.email.length > 5 && !errors.email}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ my: 1 }}>
                          <FormField
                            type={phoneNumber.type}
                            label={phoneNumber.label}
                            name={phoneNumber.name}
                            value={values.phoneNumber}
                            placeholder={phoneNumber.placeholder}
                            error={errors.phoneNumber && touched.phoneNumber}
                            success={values.phoneNumber.length > 5 && !errors.phoneNumber}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormField
                            type={message.type}
                            label={message.label}
                            name={message.name}
                            value={values.message}
                            multiline
                            rows={4}
                            placeholder={message.placeholder}
                            error={errors.message && touched.message}
                            success={values.message.length > 10 && !errors.message}
                          />
                          {/*<MKInput*/}
                          {/*  variant="standard"*/}
                          {/*  label="Your Message"*/}
                          {/*  rows={4}*/}
                          {/*  InputLabelProps={{ shrink: true }}*/}
                          {/*  multiline*/}
                          {/*  fullWidth*/}
                          {/*/>*/}
                        </Grid>
                      </Grid>
                      {alertMessage && (
                        <MKAlert color="error" dismissible>
                          <MKTypography variant="body2" color="white">
                            {alertMessage}
                          </MKTypography>
                        </MKAlert>
                      )}
                      <Grid container>
                        <Grid item xs={12} sx={{ mb: 3, ml: -1 }}>
                          <Switch id="flexSwitchCheckDefault" checked={checked} onChange={toggleSwitch}/>
                          <MKTypography
                            component="label"
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            htmlFor="flexSwitchCheckDefault"
                            sx={{ userSelect: "none", cursor: "pointer" }}
                          >
                            I agree to the{" "}
                            <MKTypography component="a" href="https://aceoar.io/privacy-policy/" variant="button" fontWeight="regular" target={"_blank"}>
                              <u>Privacy Policy</u>
                            </MKTypography>
                            &nbsp;
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12}>
                          <MKButton
                            disabled={isSubmitting || !checked || !touched || !isValid || !recaptchaLoaded}
                            type="submit" variant="gradient" color="dark" fullWidth>
                            Send Message
                          </MKButton>
                          {/*{recaptchaLoaded && 'recaptchaLoaded'}*/}
                        </Grid>
                      </Grid>
                      <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        theme="dark"
                        size="invisible"
                        ref={_reCaptchaRef}
                        sitekey={CS_LANDING_GOOGLE_SITE_KEY}
                        onChange={change}
                        asyncScriptOnLoad={asyncScriptOnLoad}
                      />
                    </MKBox>

                  </Form>
                )}
              </Formik>
            </MKBox>

          </Grid>
        </Grid>

      </Container>
    </MKBox>
  );
}

export default Contact;
