import { useNavigate } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// MKit React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// MKit React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// HomePage page sections
import AppBuilderDesc from "./sections/AppBuilderDesc";
import WebFronts from "./sections/WebFronts";
import Testimonials from "./sections/Testimonials";
import Pricing from "./sections/Pricing";

// HomePage page components
import FastToMarket from "./components/FastToMarket";

// Routes
// import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import HeaderHome from "./sections/HeaderHome";
import { useEffect, useRef } from "react";
import Rellax from "rellax";
import Features from "./sections/Features";
import SampleWorkflow from "../common-sections/SampleWorkflow";
import HowItWorks from "./sections/HowItWorks";
import platformFlowImg from "assets/images/cs/personas.svg";
import Benefits from "./sections/Benefits";
import MKButton from "../../components/MKButton";
import NoCustData from "./components/NoCustData";
import Pages from "./sections/Pages";
import Capabilities from "./sections/Capabilities";
import CaseManagement from "./sections/CaseManagement";
import Counters from "./sections/Counters";
import withPublicLayout from "../../layouts/PublicLayout";
import PublicLayout from "../../layouts/PublicLayout";
import ToolSuite from "./sections/Tool-Suite";
import Challenges from "./sections/Challenges";
import Purpose from "./sections/Purpose";
import WhatIsConsen from "./sections/What-Is-Consen";
import WhyChoose from "./sections/WhyChoose";
import bgImage from "../../assets/images/cs/cs-bg/vshadow/7.png";
import bgContact from "assets/images/wide_headers/10.png";
function LandingPage() {
  const headerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6
    });

    return () => parallax.destroy();
  }, []);

  return (
    <PublicLayout title={"Digital Application Intake & Workflow Solution"}>
      <MKBox
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            ` url(${bgImage})`,
          backgroundSize: "contain",
          backgroundPosition: "left",
        }}
      >
        {/* <DefaultNavbar */}
        {/*   routes={routes} */}
        {/*   action={{ */}
        {/*     type: "external", */}
        {/*     route: "https://www.creative-tim.com/product/material-kit-pro-react", */}
        {/*     label: "buy now", */}
        {/*     color: "info" */}
        {/*   }} */}
        {/*   transparent */}
        {/*   light */}
        {/* /> */}

        <MKBox ref={headerRef} minHeight="80vh" width="100%">
          <HeaderHome />
        </MKBox>

        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            // backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            //     ` url(${bgImage})`,
            //   backgroundSize: "contain",
            //   backgroundPosition: "left",
          }}
        >

          <Features />

          {/* <Challenges/> */}




          {/* <Container> */}
            {/* <NoCustData/> */}
          {/* </Container> */}



          <HowItWorks />

          <AppBuilderDesc />

          <Container sx={{ mt: 12 }}>
            <Grid container spacing={3} justifyContent={"center"}>

              <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}>
                <MKTypography variant="h2" fontWeight="bold" mb={0.5}
                              sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                  fontSize: size["2xl"]
                                }
                              })}
                >
                  Effortlessly Design Your Workflows
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Your non-technical business users can easily map out and modify workflows, ensuring a tailored and
                  efficient intake process
                  that aligns with your unique business requirements.
                </MKTypography>
              </Grid>
              <Grid item xs={12} lg={12} sx={{ textAlign: { xs: "center" } }} mb={0}>
                <SampleWorkflow />
              </Grid>
              <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }} mt={-8} mb={6}>
                <MKButton color="error" variant={"gradient"} onClick={()=>navigate('/platform/overview')} >
                  See What's Possible in Consen Workflow
                </MKButton>
                <br />
                <MKTypography variant="caption" color="text">
                  Please let us know if something you need is not available.
                </MKTypography>
              </Grid>
              {/* <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }} mt={4} mb={6}> */}
              {/* </Grid> */}
            </Grid>


          </Container>

          <WebFronts />


          <Purpose/>


          {/* <ToolSuite/> */}

          <WhatIsConsen/>

          <WhyChoose/>

          {/* <Container sx={{ mt: -4, mb:8 }}> */}
          <Grid item xs={12} lg={12} sx={{ textAlign: { xs: "center" } }}>
            <MKBox component="img" src={platformFlowImg} alt="personas" width="100%" />
          </Grid>
          {/* </Container> */}







          {/* <Pages /> */}


          {/* <Counters/> */}


          <Benefits />


          {/* <Container sx={{ mt: 6 }}> */}
          {/*   <FastToMarket /> */}
          {/* </Container> */}

          {/* <Capabilities /> */}

          {/* <CaseManagement/> */}


          {/* <Testimonials /> */}

          {/* <Pricing /> */}



          <MKBox pt={18} pb={6}

          >
            <Container>
              <MKBox display="flex"
                     justifyContent="space-between"
                     alignItems="center"
                     borderRadius="xl"
                     my={4}
                     p={6}
                     sx={{
                backgroundImage: `url(${bgContact})`,
                backgroundSize: "cover",
              }}
                     >
              <Grid container spacing={3} >
                <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                  <MKTypography variant="h3" fontWeight="bold" mb={0.5} color={"white"}>
                    See how Consen delivers the fastest time-to-value in your operations
                  </MKTypography>
                  {/* <MKTypography variant="body1" color="text"> */}
                  {/*   Drop us a line */}
                  {/* </MKTypography> */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  my={{ xs: 5, lg: "auto" }}
                  mr={{ xs: 0, lg: "auto" }}
                  sx={{ textAlign: { xs: "center", lg: "right" } }}
                >
                  <MKButton
                    component="a"
                    href="/contact-us"
                    // target="_blank"
                    color="primary" size={"large"}
                    sx={{ mr: 1 }}
                    onClick={()=>navigate('/contact-us')}
                  >
                    Contact Us
                  </MKButton>
                  {/* <MKSocialButton */}
                  {/*   component="a" */}
                  {/*   href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-design-system-pro" */}
                  {/*   target="_blank" */}
                  {/*   color="facebook" */}
                  {/*   sx={{ mr: 1 }} */}
                  {/* > */}
                  {/*   <i className="fab fa-facebook" /> */}
                  {/*   &nbsp;Share */}
                  {/* </MKSocialButton> */}
                  {/* <MKSocialButton */}
                  {/*   component="a" */}
                  {/*   href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-design-system-pro" */}
                  {/*   target="_blank" */}
                  {/*   color="pinterest" */}
                  {/* > */}
                  {/*   <i className="fab fa-pinterest" /> */}
                  {/*   &nbsp;Pin it */}
                  {/* </MKSocialButton> */}
                </Grid>
              </Grid>
              </MKBox>
            </Container>
          </MKBox>
        </Card>
        {/* <MKBox pt={6} px={1} mt={6}> */}
        {/*   <DefaultFooter content={footerRoutes} /> */}
        {/* </MKBox> */}
      </MKBox>
    </PublicLayout>
  );
}

export default LandingPage;
