
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// MKit React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// MKit React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// HelpCenter page sections
import SocialAnalytics from "./sections/SocialAnalytics";
import Faq from "./sections/Faq";
import Features from "./sections/Features";
import Contact from "../common-sections/Contact/Contact";

// Routes
// import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/cs-bg/5.png";
import PublicLayout from "../../layouts/PublicLayout";

import { Form, Formik, useFormik, useFormikContext } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
function ContactUs() {
  return (
    <PublicLayout title={"Contact Us"}>
      {/* <DefaultNavbar */}
      {/*   routes={routes} */}
      {/*   action={{ */}
      {/*     type: "external", */}
      {/*     route: "https://www.creative-tim.com/product/material-kit-pro-react", */}
      {/*     label: "buy now", */}
      {/*     color: "info", */}
      {/*   }} */}
      {/*   transparent */}
      {/*   light */}
      {/* /> */}
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              mb={2}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              How can we help you?
            </MKTypography>
            {/* <MKButton variant="gradient" color="info"> */}
            {/*   search issue */}
            {/* </MKButton> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        {/* <SocialAnalytics /> */}
        {/* <Faq /> */}
        {/* <Features /> */}
        <Contact />
      </Card>
      {/* <MKBox pt={6} px={1} mt={6}> */}
      {/*   <DefaultFooter content={footerRoutes} /> */}
      {/* </MKBox> */}
    </PublicLayout>
  );
}

export default ContactUs;
