import React from "react";
// react plugin used to create google maps

// reactstrap components
import Container from "@mui/material/Container";
//
import {Helmet} from 'react-helmet'
import { AppName } from "../../static-data/app-constants";
import PublicLayout from "../../layouts/PublicLayout";
// core components


function TermsOfUse(props) {

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    loadPolicyContent(window,document);
  });

  const loadPolicyContent =(w,d) => {
    var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}
  }

  // function (w, d) {
  //   var loader = function () {
  //     var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
  //     s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);
  //   };
  //   if(w.addEventListener){
  //     w.addEventListener("load", loader, false);}
  //   else if(w.attachEvent){
  //     w.attachEvent("onload", loader);
  //   }else{w.onload = loader;}
  // })(window, document);


  return (
    <PublicLayout title={"Terms Of Use"}>
      {/* <div className="wrapper"> */}
      {/*   <Helmet> */}
      {/*     <title>{AppName} - Terms Of Use</title> */}
      {/*   </Helmet> */}
        {/* <ContactUsHeader  sizeClass={"page-header-mini"}  /> */}
        <div className="main">
          <div className="contact-content">
<Container>

  <br/>
  <br/>
  <br/>
  <br/>

  <a href="https://www.iubenda.com/terms-and-conditions/47738173"
     className="iubenda-white no-brand iubenda-embed iub-no-markup iub-body-embed" title="Terms and Conditions">Terms
    and Conditions</a>



</Container>
          </div>
        </div>

      {/* </div> */}
    </PublicLayout>
  );
}

export default TermsOfUse;
