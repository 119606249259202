import PlatformOverview from "./pages/platform/overview";
import HomePage from "./pages/HomePage";
import Icon from "@mui/material/Icon";

const pageRoutes = [
  {
    name: "Platform",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Overview",
        icon: <Icon>home</Icon>,
        collapse: [
          { name: "Overview", route: "/platform/overview" },
        ],
      },
      {
        name: "Capabilities",
        icon: <Icon>home</Icon>,
        collapse: [
          { name: "Workflow Management", route: "/platform/overview" },
          { name: "Application Builder", route: "/platform/overview" },
          { name: "Form Builder", route: "/platform/overview" },
          { name: "Data Conversion", route: "/platform/overview" },
        ],
      },
      {
        name: "Integrations",
        icon: <Icon>home</Icon>,
        collapse: [
          { name: "Connectors", route: "/platform/overview" },
          { name: "Verifications", route: "/platform/overview" },
          { name: "Unification", route: "/platform/overview" },
        ],
      },

    ],
  },
  {
    name: "Solutions",
    icon: <Icon>apps</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    collapse: [

      {
        name: "By Industries",
        icon: <Icon>home</Icon>,
        collapse: [
          { name: "Banking & Financial Services", route: "/contact-us" },
          { name: "Insurance", route: "/contact-us" },
          { name: "Healthcare", route: "/contact-us" },
          { name: "Legal Services", route: "/contact-us" },
          { name: "Human Resources", route: "/contact-us" },
          { name: "Education", route: "/contact-us" },
          { name: "Real Estate", route: "/contact-us" },
          { name: "Government and Public Sector", route: "/contact-us" },
        ],
      },
      {
        name: "By Use Cases",
        icon: <Icon>home</Icon>,
        collapse: [
          { name: "Digital transformation", route: "/home" },
          { name: "Application intake", route: "/platform/overview" },
          { name: "Customer Intake", route: "/platform/overview" },
          { name: "Process management", route: "/platform/overview" },
          { name: "Case management", route: "/platform/overview" },
          { name: "Workflow software", route: "/platform/overview" },
          { name: "Legacy modernization", route: "/platform/overview" },
        ],
      },

    ],
  },
  {
    name: "help & support",
    icon: <Icon>help</Icon>,
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Documentation",
        icon: <Icon>home</Icon>,
        collapse: [
          { name: "knowledge center", route: "/contact-us"  },
        ],
      },
      {
        name: "Contact",
        icon: <Icon>home</Icon>,
        collapse: [
          { name: "contact us", route: "/contact-us"  },
          { name: "custom development", route: "/contact-us"  },
        ],
      },

      {
        name: "Partners & Investors",
        icon: <Icon>home</Icon>,
        collapse: [
          { name: "Partners", route: "/contact-us"  },
          { name: "Investors", route: "/contact-us"  },
          { name: "Integration Partners", route: "/contact-us"  },
        ],
      },

    ],
  },
];

export default pageRoutes;
