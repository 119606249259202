
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// MKit React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// MKit React examples
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// HelpCenter page components
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Images
import bgImage1 from "assets/images/cs/sqr-imgs/4.png";
import bgImage2 from "assets/images/cs/sqr-imgs/9.png";

function Benefits() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          xs={10}
          lg={8}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          {/* <MKBox */}
          {/*   width="3rem" */}
          {/*   height="3rem" */}
          {/*   borderRadius="lg" */}
          {/*   shadow="md" */}
          {/*   variant="gradient" */}
          {/*   bgColor="info" */}
          {/*   color="white" */}
          {/*   display="flex" */}
          {/*   alignItems="center" */}
          {/*   justifyContent="center" */}
          {/* > */}
          {/*   <Icon fontSize="small" sx={{ opacity: 0.8 }}> */}
          {/*     settings */}
          {/*   </Icon> */}
          {/* </MKBox> */}
          <MKTypography variant="h2" mt={3} color={"info"}>
            Reduce Friction, Increase Productivity
          </MKTypography>
          {/* <MKTypography variant="body2" color="text"> */}
          {/*   s */}
          {/* </MKTypography> */}
        </Grid>
        <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
            <DefaultBackgroundCard
              image={bgImage1}
              label="Customers"
              title="Improved Customer Experience"
              description="Provide a seamless and user-friendly experience for customers submitting information."
              action={{
                type: "internal",
                route: "/contact-us",
                label: "get started",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <ListItem title="Streamlined Flow">
              Streamline customer experience from pre-screening, to agreeing to your Terms to validated forms/ files submission,
              allowing your team to focus on what truly matters.
            </ListItem>
            <ListItem title="Data Unification with Consent">
              Allowing customers to consent to unify data from various sources can reduce churn and provide necessary information in a single interaction.
            </ListItem>
            <ListItem title="Verified Data">
              Give customers opportunity to verify their identity, address and phone numbers through various integrations to help expedite processing.
            </ListItem>
          </Grid>
        </Grid>
        <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <ListItem title="Case and Task Management">
              Integrated case and task management enables Employees focus on specific submissions, collaborate and drive the process forward increasing productivity.
            </ListItem>
            <ListItem title="Effortless Review Process">
              Employees can review customer submissions faster with complete information including files and verifications data when needed, saving time and improving efficiency.
            </ListItem>
            <ListItem title="Accelerate Processing Time">
              Speed up your operations by integrating CONSEN into your existing systems, means your Employees / Agents gets faster feedback on the outcome.
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <DefaultBackgroundCard
              image={bgImage2}
              label="Employee / Agent"
              title="Increased Productivity"
              description="Enhance operational efficiency and accelerate processing"
              action={{
                type: "internal",
                route: "/contact-us",
                label: "get started",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Benefits;
