import React from "react";
import { Handle, Position } from "reactflow";

import MKBox from "components/MKBox";
import DefaultInfoNodeCard from "./DefaultInfoNodeCard";

const handleStyle = { left: 10 };

const connectionNodeIdSelector = (state) => state.connectionNodeId;

function TnCNode({ id, data, isConnectable, selected }) {


  return (
    <MKBox
      alignItems="center"
      width="12rem"
      height="10rem"
      variant="gradient"
      bgColor={"white"}
      // color="primary"
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        border: selected ? `${borderWidth[1]} solid ${light.main}` : "none"
      })}
      shadow="md"
      borderRadius="md"
      mx={2}
      // mt={-2}
      py={2}
    >
      <Handle id="c" type="target"
              position={Position.Left} isConnectable={isConnectable} />

      <MKBox
        width="100%"
        mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}
        // sx={{
        //   background: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
        //     `${linearGradient(
        //       rgba(gradients.light.main, 1.0),
        //       rgba(gradients.light.state, 1.0)
        //     )}`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "50%",
        //   overflow: "hidden",
        // }}

      >
        <DefaultInfoNodeCard
          nodeData={data}
          color={data.color || "warning"}
          icon={data.icon || "info"}
          title={data.label}
          description={"Use Default T&C"}
          value={!selected && data.value ? data.value.name : ""}
          // value={selectedAppl.name }
        />
        {/*<MKTypography variant="h6" fontWeight="medium">*/}
        {/*{JSON.stringify(data.value)}*/}
        {/*</MKTypography>*/}


      </MKBox>
      {/*<Handle*/}
      {/*  type="source"*/}
      {/*  position={Position.Left}*/}
      {/*  id="a"*/}
      {/*  style={handleStyle}*/}
      {/*  isConnectable={isConnectable}*/}
      {/*/>*/}
      <Handle type="source" position={Position.Right} id="b" isConnectable={isConnectable} />
    </MKBox>
  );
}

export default TnCNode;
