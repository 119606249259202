
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// MKit React components
import MKBox from "components/MKBox";

// MKit React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKTypography from "../../../components/MKTypography";
import FilledInfoCard from "../../../examples/Cards/InfoCards/FilledInfoCard";
import platformFlowImg from "../../../assets/images/cs/CS-web-gifs/2.gif";

function WhatIsConsen() {
  return (
    <MKBox component="section" py={1} mt={1}>
      <Container sx={{ mt: 12 }}>
        {/* <MKBox component="section" py={3} mt={8}> */}
        <Grid container spacing={3} justifyContent={"center"}>
          {/* <Grid item xs={12} lg={8} sx={{ textAlign: { xs: "center" } }}> */}
          {/*   <MKTypography variant="h2" fontWeight="bold" mb={0.5} */}
          {/*                 sx={({ breakpoints, typography: { size } }) => ({ */}
          {/*                   [breakpoints.down("md")]: { */}
          {/*                     fontSize: size["2xl"] */}
          {/*                   } */}
          {/*                 })} */}
          {/*   > */}
          {/*     /!* Enterprise-grade platform, Small-business size effort *!/ */}
          {/*     /!* Transform Your Operations With An Enterprise-grade Intake Platform *!/ */}
          {/*     What Is CONSEN? */}
          {/*   </MKTypography> */}
          {/*   <MKTypography variant="body1" color="text"> */}
          {/*     CONSEN is an Enterprise-grade, purpose built platform for businesses to streamline their customer */}
          {/*     information intake process. */}
          {/*     /!* Your Digital Transformation initiatives doesn't have to halt because of IT backlogs anymore. Your *!/ */}
          {/*     /!* non-technical business users can now *!/ */}
          {/*     /!* build your application intake process. *!/ */}
          {/*   </MKTypography> */}
          {/* </Grid> */}
          <Grid item xs={12} lg={12} sx={{ textAlign: { xs: "center" } }}>
            <MKBox component="img" src={platformFlowImg} alt="platform" width="80%" />
          </Grid>
        </Grid>
        {/* </MKBox> */}
        {/* <SampleWorkflow /> */}
      </Container>
    </MKBox>
  );
}

export default WhatIsConsen;
